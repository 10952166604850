@use "src/styles/variables";

.riskBanner {
  background-color: #fee4e4;
  padding: 18px 20px;
  font-size: variables.px2rem(20px);
  line-height: 22px;
  font-family: "Lato", Arial;

  position: sticky;
  top: 0;
  z-index: 99;

  display: grid;
  align-items: center;
  grid-template-columns: 1fr 24px;
  gap: 10px;

  a {
    text-decoration: underline;
    color: inherit;
    font-weight: 600;
  }
}
