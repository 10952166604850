.actions {
  width: 100%;

  > div {
    width: 100%;
  }

  .carriedOverAccept {
    margin-top: 0;
  }
  .carriedOverDecline {
    border: 1px solid #2ec973 !important;
    text-decoration: none !important;
    border-radius: 10px !important;
    height: 52px !important;
    margin-top: 10px;
  }
}
