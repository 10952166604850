@use "src/styles/variables";
@use "src/styles/colors";

.HowToUpgradeToTier4Modal {
  :global(.ant-modal-close) {
    width: variables.px2rem(36px);
    height: variables.px2rem(36px);

    svg {
      color: colors.$grey-900;
      width: variables.px2rem(15px);
      height: variables.px2rem(15px);
    }
  }

  :global(.ant-modal-content) {
    @media (max-width: 768px) {
      padding: variables.px2rem(20px);

    }

    padding: variables.px2rem(40px) variables.px2rem(80px);
  }
}

.modalFooter {
  text-align: center;
  margin-top: variables.px2rem(40px);

  button {
    width: 100%;
    margin-bottom: variables.px2rem(15px);
  }

  a {
    text-decoration: underline;
    color: colors.$grey-900;
  }
}

.modalContent {
  color: colors.$grey-900;
  font-weight: 400;
  font-size: variables.px2rem(16px);
  line-height: variables.px2rem(24px);

  .modalTitle {
    font-weight: 600;
    font-size: variables.px2rem(20px);
    line-height: variables.px2rem(24px);
  }

  .modalExplanation {
    background-color: #F9F9F9;
    border: 1px solid #E4E4E4;
    border-radius: 20px;
    padding: 30px;
    margin-top: variables.px2rem(14px);

    :global(.ant-space-item) {
      pointer-events: none;
      background-color: white;
    }

    :global(.answer-selected) {
      border-color: #FFB546;
      background-color: white;

      :global(.ant-space-item) {
  
        &:first-child {
          svg {
            stroke: #FFB546;
            fill: #FFB546;
          }
        }
      }
    }
  }
}


